
import axios from 'axios';
export default {
    
    async createShopItemHistories(params)  {
        return await axios.post(`shop_item_histories/create` , params)
    },
    async createShopItemHistoriesList(params)  {
        return await axios.post(`shop_item_histories/create/list` , params)
    },
    async updateShopItemHistoriesColumn(column , value , data)  {
        return await axios.put(`shop_item_histories/update_list?${column}=${value}` , data)
    },
    async deleteShopItemHistoriesList(list)  {
        return await axios.delete(`shop_item_histories/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportShopItemHistories(column , value)  {
        return await axios.get(`shop_item_histories/report?${column}=${value}`)
    },
    async getAllShopItemHistories()  {
        return await axios.get(`shop_item_histories/all`)
    },
    async getOneShopItemHistories(shop_item_history_id)  {
        return await axios.get(`shop_item_histories/all/${shop_item_history_id}`)
    },
    async getShopItemHistoriesByColumn(column , value)  {
        return await axios.get(`shop_item_histories/filter?column=${column}&value=${value}`)
    },
    async deleteShopItemHistories(shop_item_history_id)  {
        return await axios.delete(`shop_item_histories/delete/${shop_item_history_id}`)
    },
    async updateShopItemHistories(shop_item_history_id , params)  {
        return await axios.put(`shop_item_histories/update/${shop_item_history_id}` , params)
    }
}